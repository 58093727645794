@tailwind base;
@tailwind components;
@tailwind utilities;

@import "slick.css";

div {
  /* @apply border border-red-500; */
}

body,
html,
#root {
  @apply w-full h-full overflow-hidden;
}

body {
  @apply fixed;
  @apply pb-safe-bottom;
}

#root {
  @apply relative;
}

#NFTDetails a,
#PrintDetails a,
#AboutDetails a {
  @apply underline;
}

.grid-cols-\[auto\2c 1fr\] {
  grid-template-columns: auto 1fr;
}
.grid-cols-\[1fr\2c auto\] {
  grid-template-columns: auto 1fr;
}

details summary::-webkit-details-marker,
details summary::marker {
  padding-right: 0px;
  margin-right: 0px;
}

/* reset focus states */
*,
*:focus,
*:hover {
  outline: 0px transparent !important;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.slick-slide:not(.slick-active) .tutorialCard {
  @apply bg-white/5 text-white/5;
}

.bg-gradient-default {
  @apply bg-gradient-to-b from-nft-nearBlack to-nft-blue;
}
.bg-gradient-default-inverted {
  @apply bg-gradient-to-t from-nft-nearBlack to-nft-blue;
}
.bg-gradient-soulprint {
  @apply bg-gradient-to-b from-soulprint-blue to-soulprint-green;
}
.bg-gradient-soulprint-inverted {
  @apply bg-gradient-to-t from-soulprint-blue to-soulprint-green;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 100;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-UltraLight.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 200;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Thin.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Light.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Medium.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-ExtraBold.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 900;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Black.otf) format("truetype");
}

@font-face {
  font-family: "Minecraft";
  src: url(./assets/fonts/Minecraft/Minecraft.ttf) format("truetype");
}
